import { mapActions, mapGetters, mapMutations } from 'vuex'
import course from '@/mixins/course'

export default {
  name: 'courses-view',
  mixins: [course],
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      settingLoading: 'courses/settingLoading',
      info: 'coursesInfo/info',
      packagesList: 'coursesPackages/list',
      profile: 'profile/profile',
      courseModules: 'coursesSections/courseModules'
    }),
    showGraduateWork () {
      return this.courseModules.find(e => e.component_id === 9)
    }
  },
  created () {
    // set available locales
    if (this.$route.params.hasOwnProperty('course_id')) {
      this.fetchCourse({ id: this.$route.params.course_id }).then(response => {
        const defaultCountry = response.country.data.country_id
        const countryIds = response.countries.data.map(e => e.country_id)
        this.setCountryIds([...countryIds, defaultCountry])
        this.setCourseSkills(this.info.skills.data)
      })
    }
    // end
    if (this.$route.name === 'courses.create') {
      this.changeLockStep(true)
      this.setCourseStep(1)
    } else {
      this.changeLockStep(false)
      !this.packagesList.length && this.fetchPackages({ id: this.$route.params.course_id })
    }
    if (!this.setting) {
      this.fetchSetting()
    }
    if (this.$route.name !== 'courses.create') {
      if (!this.courseModules.length) {
        this.fetchCourseComponents({ id: this.$route.params.course_id })
      }
    }

    if (!this.categories) {
      this.fetchCategories()
    }
    if (!this.types) {
      this.fetchTypes()
    }
  },
  methods: {
    ...mapActions({
      fetchSetting: 'courses/COURSES_GET_SETTING',
      fetchCategories: 'courses/COURSES_GET_CATEGORIES',
      fetchTypes: 'courses/COURSES_GET_TYPES',
      fetchPackages: 'coursesPackages/PACKAGES_GET_LIST',
      fetchCourse: 'coursesInfo/INFO_GET_COURSE',
      fetchCourseComponents: 'coursesSections/GET_LIST'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS',
      setCourseSkills: 'coursesSkills/SET_COURSE_SKILLS',
      changeLockStep: 'courses/COURSES_CHANGE_LOCK_STEP',
      resetInfo: 'coursesInfo/INFO_SET_COURSE',
      resetInfoSeo: 'coursesInfo/INFO_SET_SEO',
      resetPackages: 'coursesPackages/PACKAGES_SET_LIST',
      resetComponents: 'coursesSections/SET_LIST',
      resetStore: 'coursesAppearance/APPEARANCE_SET_STORE',
      resetHeader: 'coursesAppearance/APPEARANCE_SET_HEADER',
      resetModules: 'modules/SET_LIST'
    })
  },
  destroyed () {
    this.resetInfo(null)
    this.resetInfoSeo(null)
    this.resetStore(null)
    this.resetHeader(null)
    this.resetModules(null)
    this.resetPackages([])
    this.resetComponents([])
  }
}
